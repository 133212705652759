<template>
   <div class="partner">
       <h1>河伙人</h1>
       <h2>成为河伙人您将拥有</h2>
       <p>高额的佣金福利，考试培训名字辅导</p>
        <h2>我们希望您是</h2>
       <p>认可河小象理念、有丰富的宝妈好友、富有想法，创造力精神</p>
    </div> 
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.partner{
    text-align: left;
    h1{
        color: #444144;
        font-size: 50px;
    }
    h2{
        font-size: 32px;
        color: #FF7041;
        margin: 32px 0 17px;
    }
    p{
        font-size: 18px;
        color: #444144;
        line-height: 34px;
    }
}
</style>
